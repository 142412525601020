<template>
  <div class="m-send-report m-modal__body">
    <label class="mb-1">
      {{ t("modals.sendReport.recipients_add") }}
    </label>
    <m-search
      id="send_report_add_email"
      v-model:query="query"
      :options="availableRecipientsList"
      :placeholder="t('modals.sendReport.recipients_placeholder')"
      :validation="validation"
      freeSearch
      floating
      type="autocomplete"
      class="mb-7"
      @select="addRecipient"
      @resolve="addRecipient"
    />
    <div>
      <div class="m-send-report__heading">
        <label class="mb-1">
          {{ t("modals.sendReport.recipients") }}
        </label>
        <m-button
          v-if="recipientsList.length"
          id="clear_all"
          :label="t('modals.sendReport.recipients_clear')"
          type="text"
          variant="secondary"
          size="xsmall"
          @click="clearAll"
        />
      </div>
      <div
        class="m-send-report__list"
        :class="{ 'm-send-report__list--center': !recipientsList.length }"
      >
        <h6 v-if="!recipientsList.length" class="type--small type--empty">
          {{ t("modals.sendReport.recipients_empty") }}
        </h6>
        <div
          v-for="(recipient, i) in recipientsList"
          :key="i"
          class="m-list__element h5 type--small m-clickable"
          @mouseenter="hover = recipient.id"
          @mouseleave="hover = undefined"
        >
          <m-user-badge
            v-if="recipient.type == 'user'"
            :user="recipient"
            type="badge"
          />
          <m-team-badge
            v-else-if="recipient.type == 'team'"
            :team="recipient"
            type="badge"
          />
          <span v-else>{{ recipient.email }}</span>
          <m-icon
            id="remove_email"
            :icon="hover == recipient.id ? 'close' : 'none'"
            variant="terciary"
            @click="remove(i)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="m-modal__footer">
    <m-button
      id="m_modal_cancel"
      :label="t('general.buttons.cancel')"
      type="text"
      variant="terciary"
      class="mt-3"
      @click="slotProps.cancel"
    />
    <m-button
      id="m_modal_continue"
      :label="t('general.buttons.send')"
      type="contained"
      variant="primary"
      class="mt-3 ml-6"
      :disabled="disabled"
      @click="send"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import DateTimeUtils from "@utils/dateTime";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import MIcon from "@components/MIcon.vue";
import MButton from "@components/MButton.vue";
import MSearch from "@components/MSearch.vue";
import MUserBadge from "@components/MUserBadge.vue";
import MTeamBadge from "@components/MTeamBadge.vue";
import { useRoute } from "vue-router";

const { t } = useI18n();
const route = useRoute();
const alertsStore = useAlertsStore();
const userStore = useUserStore();
const workspacesStore = useWorkspacesStore();
const { api } = useApi();

/** @type {<{slotProps:{report: import("@root/types.api.local").MonitioAPI.ReportDTO, template: import("@root/types.api.local").MonitioAPI.ReportTemplateDTO, moduleData: any}}>} */
const props = defineProps({
  slotProps: Object,
});

const viewId = computed(() => route.params.viewId);
const hover = ref(undefined);
const recipientsList = ref(props.slotProps.report?.template?.recipients || []);
const query = ref("");

const disabled = computed(() => {
  return recipientsList.value.length <= 0;
});

watch(
  () => disabled.value,
  (val) => {
    // eslint-disable-next-line vue/no-mutating-props
    props.slotProps.disabled = val;
  },
  { immediate: true }
);

const availableTeamsList = computed(() => {
  const teams =
    userStore.details?.teams
      ?.filter((x) => x.active)
      .map((m) => ({
        ...m,
        label: m.name,
        value: m.id,
        type: "team",
      })) || [];

  recipientsList.value?.forEach((fe) => {
    const idx = teams.findIndex((f) => f.id == fe.id);
    if (idx != -1) teams.splice(idx, 1);
  });

  return teams;
});

const availableUsersList = computed(() => {
  const users =
    workspacesStore?.currentWorkspaceConfig?.workspaceUsers
      .filter((x) => x.active)
      .map((m) => ({
        ...m,
        value: m.id,
        label: `${m.accountDetails.firstName} ${m.accountDetails.lastName}`,
        type: "user",
      })) || [];

  recipientsList.value?.forEach((fe) => {
    const idx = users.findIndex((f) => f.id == fe.id);
    if (idx != -1) users.splice(idx, 1);
  });

  return users;
});

const availableRecipientsList = computed(() => {
  return availableTeamsList.value
    .concat(availableUsersList.value)
    ?.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
});

const validateEmail = (val) => {
  const emailFormat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailFormat.test(val);
};

const validation = (val) => {
  if (val.email && !validateEmail(val.email)) {
    return {
      type: "error",
      label: t("general.errors.invalidEmail"),
    };
  } else if (
    val.email &&
    recipientsList.value.find((f) => f.email == val.email)
  ) {
    return {
      type: "error",
      label: t("general.errors.emailExists"),
    };
  } else {
    return { type: null, label: null };
  }
};

const addRecipient = (val) => {
  if (val.type == "team" || val.type == "user") {
    recipientsList.value.push(val);
  } else if (validateEmail(val.email)) {
    recipientsList.value.push({
      type: "externalUser",
      email: val.email,
      id: val.email,
    });
    query.value = "";
  }
};

const remove = (idx) => {
  recipientsList.value.splice(idx, 1);
};

const clearAll = () => {
  recipientsList.value = [];
};

const getType = (val) => {
  if (val == "user") return "workspaceUser";
  else if (val == "team") return "team";
  else return "externalUser";
};

const send = async () => {
  const snapshot = {
    reportId: props.slotProps.report.id,
    viewId: viewId.value,
    isReccurent: false,
    description: props.slotProps.report.template.description,
    title: props.slotProps.report.template.title,
    preferredLanguage: props.slotProps.report.preferredLanguage,
    modules: props.slotProps.template.template.modules.map((m) => {
      const module = JSON.parse(JSON.stringify(m));

      /** To make a snapshot we need to set the dates to be absolute. Define them to be the aboslute time the snapshot was taken */
      if (module.settings?.dateRestriction?.isRelative) {
        const { start, end } = DateTimeUtils.getTimeFrame(
          module.settings.dateRestriction.timeFrame,
          userStore.timeZone,
          props.slotProps.report
        );
        module.settings.dateRestriction = {
          isRelative: false,
          start,
          end,
        };
      }

      const data = props.slotProps.moduleData.find(
        (x) => x.id == module.id
      )?.data;
      return {
        ...module,
        data,
        hasDynamicData: !!data,
      };
    }),
    recurrence: "weekly",
    frequence: [],
    deliveryTime: "09:00:00",
    recipients: recipientsList.value.map((m) => ({
      type: getType(m.type),
      email: m.email,
      id: m.value,
    })),
  };
  try {
    await api.reports.createSnapshot(snapshot, true);
    alertsStore.add({
      type: "toast",
      variant: "success",
      message: t("general.alerts.toast.sentReport", {
        name: props.slotProps.template.template.title,
      }),
    });
    props.slotProps.close();
  } catch (error) {
    props.slotProps.cancel();
  }
};
</script>

<style scoped lang="scss">
.m-send-report {
  @include flex(flex-start, stretch, column);

  &__heading {
    margin-bottom: $spacing-1;
    @include flex(space-between, center, row);
  }

  &__list {
    height: 30vh;
    border: 1px solid color($pri-action-inactive, 0.3);
    @include round-corners($spacing-1);
    overflow-y: auto;
    @include elevate-inset;

    &--center {
      @include flex(center, center, column);
    }

    .m-list__element {
      padding: $spacing-2 $spacing-3;
      margin: $spacing-1;
      @include flex(space-between, center, row);
      gap: $spacing-1;
    }
  }
}
</style>
