<template>
  <div
    ref="wrapperRef"
    :id="`m_search_${id}`"
    class="m-search"
    :class="`m-search--${size} m-search--${theme}`"
    v-click-outside="close"
  >
    <m-icon
      v-if="required"
      :tooltip="requiredTooltip"
      icon="emergency"
      variant="error"
      :size="size"
    />
    <div
      :id="`m_search_${id}_content`"
      ref="searchRef"
      :tabindex="disabled ? -1 : 0"
      role="input"
      :data-tooltip-content="setPlaceholder"
      data-tooltip-position="dynamic"
      class="m-search__content"
      :class="{
        'm-search__content--disabled': disabled,
        'm-search__content--hover': hoverInput,
        '--a11y': appStore.a11y,
      }"
      @mouseenter="hoverInput = true"
      @mouseleave="hoverInput = false"
      @click="focusInput"
    >
      <m-icon
        :id="`m_search_icon_${id}`"
        :data-test="`test_m_search_icon_${id}_search`"
        icon="search"
        status="active"
        :size="size"
        class="m-icon--leading"
        @click="focusInput"
      />
      <label :id="`m_search_label_${id}`" class="visually-hidden">
        {{ setPlaceholder }}
      </label>
      <input
        :id="`m_search_${id}_input`"
        ref="inputRef"
        :aria-controls="`m_search_dropdown_${id}`"
        :aria-expanded="openDropdown"
        aria-haspopup="listbox"
        :aria-labelledby="`m_search_label_${id}`"
        role="combobox"
        v-model="searchQuery"
        :placeholder="setPlaceholder"
        autocomplete="off"
        :data-autofocus="autoFocus"
        v-focus
        :readonly="disabled"
        @focus="openDropdown = true"
        @keypress="openDropdown = true"
        @keyup.enter.stop="resolve"
        @keyup.esc.stop="close"
      />
      <m-icon
        v-if="searchQuery?.trim()?.length > 0"
        :id="`m_search_icon_${id}`"
        :data-test="`test_m_icon_${id}_clear`"
        icon="clear"
        :size="size"
        class="m-icon--trailling"
        @click="clearQuery"
      />
      <m-icon
        v-else
        :id="`m_search_icon_${id}`"
        tabindex="-1"
        :data-test="`test_m_icon_${id}_none`"
        icon="none"
        :size="size"
        class="m-icon--trailling"
      />
    </div>
    <p
      v-if="validationMessage.label"
      class="m-search__validation"
      :class="`type--${validationMessage.type}`"
    >
      {{ validationMessage.label }}
    </p>
    <m-dropdown
      v-if="hasDropdown"
      ref="dropdownRef"
      :id="`m_search_${id}_dropdown`"
      :labelledBy="`m_search_${id}`"
      v-model:visible="openDropdown"
      :options="filteredOptions"
      :size="size"
      :position="position"
      :search="false"
      fullwidth
      :floating="floating"
      v-slot="slotProps"
      noresults
      :noresultsMessage="dropdownNoResults"
      :keyup="keyup"
      :keydown="keydown"
      :parent="searchRef"
      @update:selected="select"
    >
      <m-user-badge
        v-if="slotProps.option.type == 'user'"
        :user="slotProps.option"
        type="badge"
      />
      <m-team-badge
        v-else-if="slotProps.option.type == 'team'"
        :team="slotProps.option"
        type="badge"
      />
    </m-dropdown>
  </div>
</template>

<script setup>
/*
 * Monitio options component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/617578505/Search
 */
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";

import useDropdown from "@hooks/useDropdown";
import MDropdown from "@components/MDropdown.vue";
import MIcon from "@components/MIcon.vue";
import MUserBadge from "@components/MUserBadge.vue";
import MTeamBadge from "@components/MTeamBadge.vue";
import { useAppStore } from "@root/store/app";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  size: { type: String, default: "default" },
  query: { type: String },
  placeholder: { type: String },
  options: { type: Array, default: () => [], required: false },
  type: { type: String, default: "default" },
  position: { type: Array, default: () => ["left", "bottom"] },
  theme: { type: String, default: "light" },
  autoFocus: { type: Boolean, default: true },
  noresultsMessage: { type: String },
  floating: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  freeSearch: { type: Boolean, default: false },
  validation: { type: [Function, Object] },
  clearOnSelect: { type: Boolean, default: true },
  required: { type: Boolean, default: false },
});

const emit = defineEmits([
  "update:query",
  "select",
  "resolve",
  "blur",
  "is-valid",
]);

// Props validation
watch(
  () => props,
  () => {
    // size validator
    if (!["default", "small"].includes(props.size)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "size": expected string with value "default" or "small" and got "${props.size}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
      );
    }

    // options validator
    props.options?.forEach((opt) => {
      if (typeof opt != "object") {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "options": all array elements have to be objects. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
        );
      } else {
        if (opt.value === undefined || opt.value === null) {
          console.error(
            `%cComponent id: ${props.id}`,
            "font-weight:bold",
            '\n Invalid prop "options": all object entries of the array should have the "value" attribute defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
          );
        }
        if (
          opt.value != "divider" &&
          (opt.label === undefined || opt.label === null)
        ) {
          console.error(
            `%cComponent id: ${props.id}`,
            "font-weight:bold",
            '\n Invalid prop "options": all object entries of the array should have the "label" attribute defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
          );
        }
      }
    });

    // type validator
    if (!["default", "autocomplete"].includes(props.type)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "type": expected string with value "default" or "autocomplete" and got "${props.type}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/617578505/Search for instructions on how to use the MSearch component.`
      );
    }

    // position validator
    if (props.position?.length != 2) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "position": expected an array with two children defining the [ x, y] positioning. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
      );
    } else {
      if (!["left", "right"].includes(props.position[0])) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "position": expected the first element of the array to be string with value "left" or "right" and got "${props.position[0]}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
        );
      }
      if (!["top", "bottom"].includes(props.position[1])) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "position": expected the second element of the array to be string with value "top" or "bottom" and got "${props.position[1]}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
        );
      }
    }

    // theme validator
    if (!["light", "dark"].includes(props.theme)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "theme": expected string with value "light" or "dark" and got "${props.theme}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/617578505/Search for instructions on how to use the MSearch component.`
      );
    }
  },
  { immediate: true }
);

const { t } = useI18n();
const appStore = useAppStore();

const { openDropdown, closeDropdown } = useDropdown();

const searchQuery = ref(props.query || "");
const wrapperRef = ref(null);
const dropdownRef = ref(null);
const searchRef = ref(null);
const inputRef = ref(null);
const hoverInput = ref(false);

const hasDropdown = computed(() => {
  return searchQuery.value && props.type == "autocomplete" && !props.disabled;
});

const requiredTooltip = computed(() => {
  return {
    label: t("general.forms.required"),
    position: "dynamic",
  };
});

const dropdownNoResults = computed(() => {
  if (props.noresultsMessage) return props.noresultsMessage;
  else {
    return props.noresultsMessage ?? t("components.search.empty");
  }
});

const focusInput = () => {
  inputRef.value.focus();
};

const setPlaceholder = computed(() => {
  return props.placeholder ?? t("components.search.placeholder_search");
});

const filteredOptions = computed(() => {
  let filtered = [];

  if (props.options) {
    if (searchQuery.value?.trim()?.length == 0) {
      filtered = props.options;
    } else {
      props.options.forEach((f) => {
        if (!f?.label) return;
        const opt = f?.label?.toLowerCase()?.split(" ");
        const email = f?.email?.toLowerCase();
        let match = false;
        opt.forEach((x) => {
          if (x.startsWith(searchQuery.value.toLowerCase())) match = true;
        });
        if (email?.startsWith(searchQuery.value.toLowerCase())) match = true;
        if (match) filtered.push(f);
      });
    }
  } else {
    filtered = [...props.options];
  }

  if (props.freeSearch) {
    if (filtered?.length) {
      filtered.unshift(
        {
          label: searchQuery.value,
          value: searchQuery.value,
        },
        { value: "divider" }
      );
    } else {
      filtered.unshift({
        label: searchQuery.value,
        value: searchQuery.value,
      });
    }

    if (props.options?.find((f) => f?.email)) {
      filtered[0].email = searchQuery.value;
    }
  }

  return filtered.filter((m) => m.type != "team");
});

const validationMessage = ref({ type: null, label: null });

watch(
  () => searchQuery.value,
  (val, oldVal) => {
    if (val != oldVal) emit("update:query", val);
  },
  { immediate: true }
);

watch(
  () => props.query,
  (val, oldVal) => {
    if (val != oldVal) searchQuery.value = val;
  },
  { immediate: true }
);

watch(
  () => validationMessage.value,
  (val) => {
    emit("is-valid", val.type == null ? true : false);
  },
  { immediate: true }
);

const clearQuery = () => {
  searchQuery.value = "";
  emit("update:query", "");
};

const close = () => {
  closeDropdown();
  emit("blur");
};

const select = (val) => {
  const vm = validationMessage.value;
  if (typeof props.validation == "function") {
    const message = props.validation(val);
    vm.type = message.type;
    vm.label = message.label;
    if (vm.type == null) {
      emit("select", val);
      if (!props.clearOnSelect) {
        emit("blur");
        return;
      } else clearQuery();
    }
  } else if (props.validation) {
    vm.type = props.validation.type;
    vm.label = props.validation.label;
  } else {
    emit("select", val);
    if (!props.clearOnSelect) {
      emit("blur");
      return;
    } else clearQuery();
  }
  close();
  inputRef.value.focus();
};

const resolve = (val) => {
  if (props.type == "autocomplete") select(filteredOptions.value[0]);
  else emit("resolve", val);
};

const keyup = ref(null);
const keydown = ref(null);

const keyUpMovement = (evt) => {
  if (evt?.keyCode == 38 || evt?.keyCode == 40) {
    evt.preventDefault();
  }
  keyup.value = evt;
};

const keyDownMovement = (evt) => {
  if (evt?.keyCode == 38 || evt?.keyCode == 40) {
    evt.preventDefault();
  }
  keydown.value = evt;
};

onMounted(() => {
  inputRef.value.addEventListener("keyup", keyUpMovement);
  inputRef.value.addEventListener("keydown", keyDownMovement);
});

onBeforeUnmount(() => {
  inputRef.value.removeEventListener("keyup", keyUpMovement);
  inputRef.value.removeEventListener("keydown", keyDownMovement);
});

defineExpose({
  focus: focusInput,
});
</script>

<style scoped lang="scss">
.m-search {
  .m-icon--emergency {
    position: absolute;
    right: $spacing-0;
    transform: scale(0.6) translateY(calc(-100% - $spacing-4));
  }
}
</style>
