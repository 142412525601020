<template>
  <div class="p-relative">
    <div
      :id="`m_autocomplete_${id}`"
      ref="wrapperRef"
      class="m-autocomplete"
      :class="`m-autocomplete--${size}`"
      data-tour="tour_m_autocomplete"
      v-click-outside="closeDropdown"
    >
      <div
        :id="`m_autocomplete_${id}__content`"
        class="m-autocomplete__content"
        :class="{
          'm-autocomplete__content--disabled': disabled,
          'm-autocomplete__content--hover': hoverInput,
          '--a11y': appStore.a11y,
        }"
        @mouseenter="hoverInput = true"
        @mouseleave="hoverInput = false"
        @click="focusInput"
      >
        <m-icon
          :id="`m_autocomplete_icon_${id}`"
          :data-test="`test_m_autocomplete_icon_${id}_search`"
          icon="search"
          status="active"
          :size="size"
          class="m-icon--leading"
          @click="focusInput"
        />
        <label :id="`m_autocomplete_${id}_label`" class="visually-hidden">
          {{ setPlaceholder }}
        </label>
        <input
          :id="`m_autocomplete_${id}_input`"
          ref="inputRef"
          :aria-controls="`m_autocomplete_${id}_dropdown`"
          :aria-expanded="openDropdown"
          aria-haspopup="listbox"
          :aria-labelledby="`m_autocomplete_${id}_label`"
          role="combobox"
          v-model="searchQuery"
          :placeholder="setPlaceholder"
          :class="`type--${size}`"
          autocomplete="off"
          :data-autofocus="autoFocus"
          v-focus
          @keyup.up="openDropdown = true"
          @keyup.down.exact="openDropdown = true"
          @keyup.alt.down="openDropdown = true"
          @keyup.home="openDropdown = true"
          @keyup.end="openDropdown = true"
          @keyup.enter="searchQuerySelect"
          @keyup.esc="closeDropdown"
        />
        <m-loading v-if="isSearching" size="xsmall" />
        <m-icon
          v-if="searchQuery?.trim()?.length > 0"
          :id="`m_autocomplete_${id}_clear`"
          :data-test="`test_m_icon_${id}_clear`"
          icon="clear"
          :size="size"
          class="m-icon--trailling"
          @click="clearQuery"
        />
        <m-icon
          v-else
          :id="`m_icon_${id}`"
          tabindex="-1"
          :data-test="`test_m_icon_${id}_none`"
          icon="none"
          :size="size"
          class="m-icon--trailling"
        />
      </div>
      <m-dropdown
        v-if="type == 'facet'"
        :id="`m_search_dynamic_${id}_dropdown`"
        :labelledBy="`m_autocomplete_${id}`"
        v-model:visible="openDropdown"
        :options="autoCompleteResults"
        v-model:focused="focused"
        :search="false"
        fullwidth
        :size="size"
        :floating="floating"
        :keyup="keyup"
        :keydown="keydown"
        :parent="wrapperRef"
        v-slot="slotProps"
        @update:selected="selectOption"
      >
        <div v-if="slotProps.option.filter != 'customQuery'">
          <m-facet-tag :facet="slotProps.option.filter" class="mr-4" />
          {{ slotProps.option.label }}
        </div>
        <span v-else class="mr-3 type--empty">
          {{
            t("components.filterAdvanced.searchForQuery", {
              query: slotProps.option.label,
            })
          }}
        </span>
      </m-dropdown>
      <m-dropdown
        v-else
        :id="`m_sautocomplete_${id}`"
        :labelledBy="`m_autocomplete_${id}_label`"
        v-model:visible="openDropdown"
        :options="autoCompleteResults"
        :size="size"
        v-model:focused="focused"
        noresults
        :search="false"
        fullwidth
        :floating="floating"
        :keyup="keyup"
        :keydown="keydown"
        :parent="wrapperRef"
        v-slot="slotProps"
        @update:selected="selectOption"
      >
        <span v-if="slotProps.option.filter != 'customQuery'">
          {{ slotProps.option.label }}
        </span>
      </m-dropdown>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { debounce } from "lodash-es";
import { useApi } from "@api/api";
import { useUserStore } from "@root/store/modules/user";
import { useRoute } from "vue-router";
import useDropdown from "@hooks/useDropdown";
import MLoading from "@components/MLoading.vue";
import MDropdown from "@components/MDropdown.vue";
import MFacetTag from "@components/MFacetTag.vue";
import MIcon from "@components/MIcon.vue";
import * as ASCIIFolder from "fold-to-ascii";
import { useAppStore } from "@root/store/app";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  size: {
    type: String,
    default: "default",
    validator(size) {
      if (!["default", "small"].includes(size)) {
        console.error(
          `Invalid prop "size": expected string with value "default" or "small" and got "${size}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/617578505/Search for instructions on how to use the MSearch component.`
        );
      }
      return true;
    },
  },
  type: { type: String, default: "default" },
  propertyTypes: { type: Array, required: true },
  query: { type: String },
  placeholder: { type: String },
  autoFocus: { type: Boolean, default: true },
  clear: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  floating: { type: Boolean, default: false },
  allowCustomQuerySelection: { type: Boolean, default: false },
});

const emit = defineEmits(["update:query", "select", "clear"]);

const { t } = useI18n();
const { api } = useApi();
const { openDropdown, keepDropdownOpen, closeDropdown } = useDropdown();

const userStore = useUserStore();
const appStore = useAppStore();
const route = useRoute();
const viewId = computed(() => route.params.viewId);
const searchQuery = ref(props.query);
const inputRef = ref(null);
const wrapperRef = ref(null);
const focused = ref(null);
const hoverInput = ref(false);

watch(
  () => searchQuery.value,
  (val) => {
    if (val) emit("update:query", val);
  }
);

const autoCompleteResults = ref([]);
const isSearching = ref(false);

/** @type {Set<AbortController>} */
const controllers = new Set();
const getAutcomplete = debounce(async function (val) {
  if (val == "") {
    isSearching.value = false;
    return;
  }
  isSearching.value = true;

  /** @description An array with all the porperty types to send in the request, beucase we want them all */
  let data;
  try {
    const response = await api.search.autocomplete(
      viewId.value,
      val,
      props.propertyTypes.join().replaceAll("_qid", "") //We dont want to search per QIDS so we need to remove them only here
    );

    data = response.data;
  } catch (error) {
    isSearching.value = false;
    console.debug("Request canceled");
  }

  if (!data?.entries?.length && !props.allowCustomQuerySelection) {
    isSearching.value = false;
    openDropdown.value = true;
    autoCompleteResults.value = [];
    return;
  }

  const i18nLanguage = userStore.i18nLanguage || "en";
  const userTranslateFrom = userStore.account?.translateFrom ?? [];

  const formattedData =
    data?.entries?.map((entry) => {
      /** @description The label of the entity. It might be in the user's language or not */
      const label =
        entry.labels[i18nLanguage] ||
        entry.labels.en ||
        entry.labels.xx ||
        Object.values(entry.labels)[0];

      const matchingEntries = Object.entries(entry.labels).filter((x) =>
        x[1].toLowerCase().includes(val.toLowerCase())
      );
      /** @description Get a label where the string the user searched for is guarateeed to be */
      const source =
        matchingEntries?.find((x) => x[0] == i18nLanguage) ??
        matchingEntries?.find((x) => !userTranslateFrom.includes(x[0])) ??
        matchingEntries?.find((x) => x[0] == "en") ??
        matchingEntries?.find((x) => x[0] == "xx") ??
        matchingEntries?.[0];

      return {
        value: entry.key,
        filter: entry.type,
        namespace: entry.namespace,
        source: source ? { lang: source[0], value: source[1] } : null,
        description:
          entry.descriptions[i18nLanguage] ||
          entry.descriptions.en ||
          entry.descriptions.xx ||
          Object.values(entry.descriptions)?.[0],
        label: label,
      };
    }) ?? [];

  formattedData.sort((a, b) => {
    if (b.source?.lang == i18nLanguage && a.source?.lang != i18nLanguage)
      return 1;
    if (isTokenIncluded(val, b.label) && !isTokenIncluded(val, a.label))
      return 1;

    if (
      !userTranslateFrom?.includes(b.source?.lang) &&
      userTranslateFrom?.includes(a.source?.lang)
    )
      return 1;
    else return 0;
  });

  /** Put what the user wrote in 1st place as a option for a custom query if the component allows */
  if (props.allowCustomQuerySelection) {
    formattedData.unshift({
      label: searchQuery.value,
      value: searchQuery.value,
      filter: "customQuery",
    });
  }
  autoCompleteResults.value = formattedData;

  if (autoCompleteResults.value.length > 0) {
    openDropdown.value = true;
  }

  if (isSearching.value) {
    openDropdown.value = true;
    isSearching.value = false;
  }
}, 500);

/**
 * @description For when we want to update the query but avoid search again
 */
let avoidSearch = false;
watch(
  () => searchQuery.value,
  (val) => {
    if (avoidSearch) {
      avoidSearch = false;
      return;
    }
    closeDropdown();
    if (isSearching.value) {
      controllers.forEach((x) => x.abort());
    }
    isSearching.value = true;
    getAutcomplete(val);
  }
);

const focusInput = () => {
  inputRef.value.focus();
};

const setPlaceholder = computed(() => {
  return props.placeholder ?? t("components.search.placeholder_search");
});

const selectOption = (val) => {
  if (openDropdown.value) {
    if (val.namespace == "wikidata") val.filter += "_qid";
    if (searchQuery.value != "") {
      openDropdown.value = false;
      avoidSearch = true;
      searchQuery.value = val.label;
      emit("select", val);

      if (!props.clear) return;
      clearQuery();
    }
  }
};

const searchQuerySelect = () => {
  if (!props.allowCustomQuerySelection) {
    selectOption(
      autoCompleteResults.value.find((f) => f.value == focused.value) ??
        autoCompleteResults.value[0]
    );
    return;
  }

  emit("select", {
    value: searchQuery.value,
    label: searchQuery.value,
    filter: "customQuery",
  });

  if (!props.clear) return;
  clearQuery();
};

const clearQuery = () => {
  avoidSearch = true;
  searchQuery.value = "";
  closeDropdown();
  autoCompleteResults.value = [];
  emit("update:query", "");
  emit("clear");
};

/**
 * @description Function that receives a token (string) and some other string (phrase) folds/normalizes them
 * and checks if the token is included in the phrase somewhere
 */
const isTokenIncluded = (token, phrase) => {
  const foldedToken = ASCIIFolder.foldReplacing(token).toLowerCase();
  const foldedPhrase = ASCIIFolder.foldReplacing(phrase).toLowerCase();
  return foldedPhrase.includes(foldedToken);
};

const keyup = ref(null);
const keydown = ref(null);

const keyUpMovement = (evt) => {
  keyup.value = evt;
};

const keyDownMovement = (evt) => {
  keydown.value = evt;
};

onMounted(() => {
  inputRef.value.addEventListener("keyup", keyUpMovement);
  inputRef.value.addEventListener("keydown", keyDownMovement);
});

onBeforeUnmount(() => {
  inputRef.value.removeEventListener("keyup", keyUpMovement);
  inputRef.value.removeEventListener("keydown", keyDownMovement);
});

defineExpose({
  focus: focusInput,
});
</script>
