<template>
  <div class="m-modal__body m-create-smarttag">
    <div class="smarttag_config">
      <div class="">
        <label class="mb-1"> Tag category </label>
        <m-search
          label="Tag category name"
          id="m_chat_input"
          class="mb-8 mr-2"
          :placeholder="'Search existing categories'"
          :options="tagTypesOptions"
          type="autocomplete"
          freeSearch
          floating
          :autoFocus="false"
          :clearOnSelect="false"
          v-model:query="tagTypeQuery"
          @select="selectTagType"
        />
      </div>
      <m-input
        label="Smart tag name"
        id="m_chat_input"
        class="mb-8"
        v-model="tagName"
        placeholder="Insert the name of your new tag"
      />
    </div>
    <div class="article-select">
      <div class="articles m-articles__wrapper">
        <span class="type--default type--500">Articles </span>
        <template v-if="!articles?.length">
          <h6 class="type--small type--empty">
            {{ t("createSmartTagModal.loadingText") }}
          </h6>
          <m-loading />
        </template>
        <m-article
          v-else
          v-for="(article, i) in articles"
          :key="i"
          :article="article"
          layout="details"
          @click="() => getArticle(article.id)"
          :canOpen="false"
          :actions="['smart-tagging']"
          :tagStatus="
            articlesOnCurrentTag['positive'].some((x) => x.id == article.id)
              ? true
              : articlesOnCurrentTag['negative'].some((x) => x.id == article.id)
              ? false
              : null
          "
          @upvote="() => toogleUpvote(article)"
          @downvote="() => toggleDownvote(article)"
        />
      </div>
    </div>
    <div class="preview">
      <span class="type--default type--500">Article preview</span>
      <template v-if="loadingPreviewArticle">
        <h6 class="type--small type--empty">
          {{ t("createSmartTagModal.loadingPreviewArticle") }}
        </h6>
        <m-loading />
      </template>
      <div v-else-if="previewingArticle" class="article">
        <m-article
          :key="i"
          :article="previewingArticle"
          :disableActions="true"
          :actions="[]"
          layout="preview"
        />
      </div>
      <h6 v-else class="type--small type--empty">
        {{ t("createSmartTagModal.noPreviewSelected") }}
      </h6>
    </div>
  </div>
  <div class="m-modal__footer">
    <m-button
      id="m_retake_tour_cancel"
      s
      :label="t('tour.buttons.cancel')"
      type="text"
      variant="terciary"
      size="block"
      class="mt-3"
      @click="slotProps.close"
    />
    <m-button
      id="m_retake_tour_continue"
      label="Create smart tag"
      type="contained"
      variant="primary"
      size="block"
      class="mt-3 ml-6"
      :disabled="!canContinue || isLoading"
      :loading="isLoading"
      @click="continueClick"
    />
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import { useRoute, useRouter } from "vue-router";
import { useViewFilters } from "@hooks/useViewFilters";
import MButton from "@components/MButton.vue";
import MSearch from "@components/MSearch.vue";
import { isEmpty } from "lodash-es";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useUserStore } from "@root/store/modules/user";
import MLoading from "@components/MLoading.vue";
import MArticle from "@components/MArticle.vue";

import MInput from "@components/MInput.vue";
import { onMounted, computed, ref, unref } from "vue";

const props = defineProps({
  slotProps: Object,
});
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const alertsStore = useAlertsStore();
const userStore = useUserStore();
const { api } = useApi();

const viewId = computed(() => route.params.viewId);
const { queryObject, dateRestriction } = useViewFilters(router, route);

const isLoading = ref(false);
const continueClick = async () => {
  isLoading.value = true;
  try {
    const result = await api.tags.createSmartTag({
      newCategoryName:
        selectedTagType.value.label == selectedTagType.value.value
          ? selectedTagType.value.label
          : null,
      categoryId:
        selectedTagType.value.label == selectedTagType.value.value
          ? null
          : selectedTagType.value.value,
      tagName: tagName.value,
      influences: articlesOnCurrentTag.value,
    });

    if (result.status !== 200) throw new Error();

    alertsStore.add({
      type: "toast",
      variant: "success",
      message:
        "Your smart tag is beeing created. It might take a while until you start seeing it in the filter pane.",
    });
  } catch (error) {
    alertsStore.add({
      type: "toast",
      variant: "error",
      message:
        "Somethng went wrong while creating your smart tag. Please try again later.",
    });
  }

  isLoading.value = false;
  props.slotProps.close();
  return;
};

const canContinue = computed(
  () =>
    selectedTagType.value &&
    tagName.value &&
    (articlesOnCurrentTag.value["positive"].length > 0 ||
      articlesOnCurrentTag.value["negative"].length > 0)
);

const handleModal = () => {
  open("m_modal_retake_tour", {
    width: "360px",
    disabled: false,
    buttons: ["custom"],
  });
};

defineExpose({
  open: handleModal,
});

const tagName = ref("");
const tagTypeQuery = ref(props.slotProps.selectedTagType?.label ?? "");
const articlesOnCurrentTag = ref({
  positive: [],
  negative: [],
});

const selectedTagType = ref(
  props.slotProps.selectedTagType
    ? { ...props.slotProps.selectedTagType }
    : null
);

const tagTypesOptions = computed(() => [
  ...userStore.smartTagTypes.map((x) => ({ value: x.id, label: x.name })),
]);

const loadingPreviewArticle = ref(false);
const previewingArticle = ref(null);

const responseHasData = ref(true);
const articles = ref([]);
onMounted(async () => {
  const { articlesData } = await getArticles(props.slotProps.article);

  if (props.slotProps.article) {
    articlesOnCurrentTag.value["positive"].push(props.slotProps.article);
    articles.value = [
      props.slotProps.article,
      ...articlesData.filter((x) => x.id !== props.slotProps.article.id),
    ];
  } else articles.value = articlesData;
});

const loadingArticles = ref(false);
/**
 *
 * @param {string} id Its a Guid
 * @param {import("@root/types").Monitio.URLQueryObject} query
 */
const getArticles = async (article) => {
  loadingArticles.value = true;

  const response = await api.search.denseDocumentSearch(article);
  if (response.status != 200) return;

  if (response.data?.result?.documents?.length) responseHasData.value = false;

  loadingArticles.value = false;
  return {
    articlesData: response.data?.result?.documents ?? [],
  };
};

const getArticle = async (id) => {
  loadingPreviewArticle.value = true;
  const vid = viewId.value;
  try {
    const response = await api.search.getDocument(vid, id); //start by seding view id to the backend today
    previewingArticle.value = response.data;
    loadingPreviewArticle.value = false;
    return;
  } catch (error) {
    loadingPreviewArticle.value = false;
    return null;
  }
};

const selectTagType = (opt) => {
  tagTypeQuery.value = opt.label;
  selectedTagType.value = { ...opt };
};

const toogleUpvote = (article) => {
  if (articlesOnCurrentTag.value["negative"].some((x) => x.id == article.id)) {
    articlesOnCurrentTag.value["negative"] = articlesOnCurrentTag.value[
      "negative"
    ].filter((x) => x.id != article.id);
  }
  if (articlesOnCurrentTag.value["positive"].some((x) => x.id == article.id)) {
    articlesOnCurrentTag.value["positive"] = articlesOnCurrentTag.value[
      "positive"
    ].filter((x) => x.id != article.id);
  } else {
    articlesOnCurrentTag.value["positive"].push(article);
  }
};

const toggleDownvote = (article) => {
  if (articlesOnCurrentTag.value["positive"].some((x) => x.id == article.id)) {
    articlesOnCurrentTag.value["positive"] = articlesOnCurrentTag.value[
      "positive"
    ].filter((x) => x.id != article.id);
  }
  if (articlesOnCurrentTag.value["negative"].some((x) => x.id == article.id)) {
    articlesOnCurrentTag.value["negative"] = articlesOnCurrentTag.value[
      "negative"
    ].filter((x) => x.id != article.id);
  } else {
    articlesOnCurrentTag.value["negative"].push(article);
  }
};
</script>

<style scoped lang="scss">
.m-create-smarttag {
  display: flex;
  flex-wrap: wrap;
}

.articles {
  max-height: $column-4;
  padding: $spacing-0;
  margin: $spacing-0;
  overflow-y: auto;
  overflow-x: hidden;
}

.article-select,
.preview {
  width: 50%;
}

.smarttag_config {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.article-select {
  padding-right: $spacing-2;
}

.preview {
  padding: $spacing-1 0 $spacing-1 $spacing-4;
  .article {
    max-height: $column-4;
    margin: $spacing-0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: $spacing-4;
    border: 1px solid color($pri-action-inactive, 0.3);
    @include round-corners($spacing-1);
  }

  > span {
    display: block;
    margin-bottom: 3px;
  }
}
</style>
